"use client";

import { updateItemQuantityInDB } from "@/components/features/cart-modal/actions";
import { Button } from "@/components/shared/ui/button";
import { Input } from "@/components/shared/ui/input";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/shared/ui/tooltip";
import { useCartStore } from "@/state-management/stores/cart-store";
import { Pencil, Sparkles } from "lucide-react";
import React, { startTransition, useEffect, useState } from "react";
import { useFormState } from "react-dom";

interface DOIDisplayProps {
    inventory: number;
    currentDOI: number;
    dailySales: number;
    productID: number;
    currentCartUnits: number;
    wholesaleUnitsPerCase?: number;
    targetDOI?: number;
    showAutoButton?: boolean;
    className?: string;
}

const DOIDisplay: React.FC<DOIDisplayProps> = ({
    inventory,
    currentDOI,
    dailySales,
    productID,
    currentCartUnits,
    wholesaleUnitsPerCase,
    targetDOI,
    showAutoButton = true,
    className
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState(Number(currentDOI).toFixed(2));
    const [DOI, setDOI] = useState(currentDOI);
    // eslint-disable-next-line no-unused-vars
    const [message, formAction] = useFormState(updateItemQuantityInDB, null);
    const { updateCartItem } = useCartStore();

    const calculateRequiredUnits = (targetDOI: number): number => {
        const requiredInventory = targetDOI * dailySales - inventory;
        if (wholesaleUnitsPerCase) {
            const requiredCases = Math.ceil(requiredInventory / wholesaleUnitsPerCase);
            return Math.max(0, requiredCases * wholesaleUnitsPerCase);
        }
        const additionalUnits = Math.ceil(requiredInventory);
        return Math.max(0, additionalUnits);
    };

    const addCartUnitsForTarget = (newTargetDOI: number) => {
        const additionalUnits = calculateRequiredUnits(newTargetDOI);
        if (additionalUnits > 0) {
            const payload = {
                productID: productID,
                quantity: additionalUnits,
                promotionID: null
            };

            startTransition(() => {
                updateCartItem(productID, "set-units", null, additionalUnits);
            });

            formAction(payload);
        }
    };

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setIsEditing(true);
    };

    const handleBlur = () => {
        setIsEditing(false);
        const newDOI = parseFloat(editValue);
        if (!isNaN(newDOI) && newDOI >= 0) {
            addCartUnitsForTarget(newDOI);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            setIsEditing(false);
            const numericValue = parseFloat(editValue);
            if (!isNaN(numericValue) && numericValue > 0) {
                addCartUnitsForTarget(numericValue);
            }
        }
        if (e.key === "Escape") {
            setEditValue(currentDOI.toFixed(2));
            setIsEditing(false);
        }
    };

    const handleMagicClick = () => {
        if (targetDOI) {
            addCartUnitsForTarget(targetDOI);
        }
    };

    useEffect(() => {
        const totalUnits = Number(inventory) + (currentCartUnits ?? 0);
        setDOI(totalUnits / Number(dailySales));
    }, [inventory, dailySales, currentCartUnits]);

    let highlight = "";

    if (targetDOI && DOI < targetDOI) {
        highlight = "bg-orange-100 text-orange-700";
    } else if (targetDOI && DOI >= targetDOI && currentDOI < targetDOI) {
        highlight = "bg-green-100 text-green-700";
    } else if (currentCartUnits > 0) {
        highlight = "bg-blue-100 text-slate-600";
    }

    return (
        <div className={`flex w-fit items-center justify-start gap-2 ${className}`}>
            <Tooltip>
                <TooltipTrigger asChild>
                    <div className="flex w-fit flex-wrap items-start justify-end gap-2 ">
                        <div
                            onClick={handleClick}
                            className={`w-fit cursor-pointer items-center ${highlight} rounded-t-sm border-b border-b-gray-700 px-1`}
                        >
                            {isEditing ? (
                                <Input
                                    type="number"
                                    defaultValue={editValue}
                                    onChange={(e) =>
                                        setEditValue(Number(e.target.value).toFixed(2))
                                    }
                                    onBlur={handleBlur}
                                    onFocus={(e) => e.target.select()}
                                    onKeyDown={handleKeyDown}
                                    className="h-6 w-16 border-none px-1 text-right outline-none"
                                    autoFocus
                                    min="0"
                                    step="1"
                                />
                            ) : (
                                <div className="flex flex-row items-baseline gap-1 whitespace-nowrap">
                                    <span className="text-gray-500">*DOI:</span>
                                    <span className=""> {Number(DOI).toFixed(2)}</span>
                                    <span className=" text-gray-500">
                                        <Pencil className="h-3 w-3" />
                                    </span>
                                </div>
                            )}
                        </div>
                        {showAutoButton && (
                            <Button
                                variant="default"
                                size="icon"
                                className="h-6 w-fit max-w-20 bg-slate-600 px-2"
                                onClick={handleMagicClick}
                                disabled={!targetDOI || DOI >= targetDOI || isEditing}
                            >
                                <Sparkles className="h-4 w-4" />
                            </Button>
                        )}
                    </div>
                </TooltipTrigger>
                <TooltipContent>
                    <p>{`Days of Inventory = ${inventory.toLocaleString()} units in retail inventory${currentCartUnits > 0 ? ` + ${currentCartUnits} units in cart` : ""} / ${Number(dailySales).toFixed(2)} daily sales`}</p>
                    <p>Click to edit</p>
                </TooltipContent>
            </Tooltip>
        </div>
    );
};
export default DOIDisplay;
