/* eslint-disable no-unused-vars */
"use client";

import { VALID_FILTER_KEYS, defaultSort } from "@/lib/shop/shop-constants";
import { FilterValue, ShopFilters, ShopLayout, ShopSort } from "@/lib/shop/shop-types";
import { create } from "zustand";

interface ShopState {
    filters: ShopFilters;
    sort: ShopSort;
    layout: ShopLayout;
    setFilters: (filters: ShopFilters) => void;
    clearAllFilters: () => void;
    getFilter: <T extends FilterValue>(key: string) => T | null;
    setFilter: (key: string, value: FilterValue) => void;
    removeFilter: (key: string) => void;
    setSort: (sort: ShopSort) => void;
    setLayout: (layout: ShopLayout) => void;
    updateURL: () => void;
}

// Helper function to convert filter value to URL string
const filterValueToString = (value: FilterValue): string | null => {
    if (value === null || value === undefined) return null;
    if (Array.isArray(value)) return value.join(",");
    if (typeof value === "boolean") return value ? "true" : null;
    return value.toString();
};

export const useShopStore = create<ShopState>((set, get) => {
    // Get router hooks - these must be used in a component
    const getSearchParams = () => {
        if (typeof window === "undefined") return new URLSearchParams();
        return new URLSearchParams(window.location.search);
    };

    return {
        filters: {},
        sort: defaultSort,
        layout: "list",

        setFilters: (filters: ShopFilters) => {
            set(() => ({ filters }));
            setTimeout(() => get().updateURL(), 0);
        },

        clearAllFilters: () => {
            set(() => ({ filters: {} }));
            setTimeout(() => get().updateURL(), 0);
        },

        getFilter: <T extends FilterValue>(key: string): T | null => {
            const state = get();
            return (state.filters[key] as T) || null;
        },

        setFilter: (key: string, value: FilterValue) => {
            set((state) => ({
                filters: {
                    ...state.filters,
                    [key]: value
                }
            }));
            setTimeout(() => get().updateURL(), 0);
        },

        removeFilter: (key: string) => {
            set((state) => {
                const newFilters = { ...state.filters };
                delete newFilters[key];
                return { filters: newFilters };
            });
            setTimeout(() => get().updateURL(), 0);
        },

        setSort: (sort: ShopSort) => {
            set({ sort });
            setTimeout(() => get().updateURL(), 0);
        },

        setLayout: (layout: ShopLayout) => {
            set({ layout });
            setTimeout(() => get().updateURL(), 0);
        },

        updateURL: () => {
            if (typeof window === "undefined") return;

            const state = get();
            const pathname = window.location.pathname;
            const newSearchParams = getSearchParams();

            // Remove any filter parameters that aren't in our valid set
            Array.from(newSearchParams.keys()).forEach((param) => {
                if (
                    param !== "sortKey" &&
                    param !== "reverse" &&
                    param !== "layout" &&
                    VALID_FILTER_KEYS.has(param) &&
                    state.filters[param] === undefined
                ) {
                    newSearchParams.delete(param);
                }
            });

            // Update URL with current state filters
            Object.entries(state.filters).forEach(([key, value]) => {
                // Only process if it's a valid filter key
                if (VALID_FILTER_KEYS.has(key)) {
                    const stringValue = filterValueToString(value);
                    if (stringValue && stringValue !== "" && stringValue !== "false") {
                        newSearchParams.set(key, stringValue);
                    } else {
                        newSearchParams.delete(key);
                    }
                }
            });

            // Handle sort parameters
            if (state.sort.sortKey !== "supplier") {
                newSearchParams.set("sortKey", state.sort.sortKey);
            } else {
                newSearchParams.delete("sortKey");
            }

            if (state.sort.reverse) {
                newSearchParams.set("reverse", "true");
            } else {
                newSearchParams.delete("reverse");
            }

            // Handle layout parameter
            if (state.layout !== "list") {
                newSearchParams.set("layout", state.layout);
            } else {
                newSearchParams.delete("layout");
            }

            const newUrl = `${pathname}?${newSearchParams.toString()}`;
            window.history.pushState({}, "", newUrl);
        }
    };
});
