import { Blend, ShopFilters, ShopSort, SortOption } from "./shop-types";

// TODO: Can we make this array from Blend type instead of hard coding array values?
export const validBlends: Blend[] = [
    "Indica Hybrid",
    "Hybrid",
    "1:1 CBD",
    "Sativa Hybrid",
    "High CBD",
    "Sativa",
    "Indica"
];

export const gridConfig = {
    base: "grid-cols-1",
    sm: "sm:grid-cols-2",
    md: "md:grid-cols-3",
    lg: "lg:grid-cols-3",
    xl: "xl:grid-cols-4",
    "2xl": "2xl:grid-cols-5",
    "3xl": "3xl:grid-cols-6"
};

export const RESULTS_PER_PAGE = 40;

export const defaultSort: ShopSort = {
    sortKey: "supplier",
    type: "string",
    reverse: false
};

export const defaultFilters: ShopFilters = {};

export const CATEGORY_FILTER_ID = "category";
export const SUPPLIER_FILTER_ID = "brands";
export const TARGET_DOI_FILTER_ID = "target-doi";
export const SHOW_BELOW_ONLY_FILTER_ID = "show-below-only";
export const SHOW_MY_PRODUCTS_ONLY_FILTER_ID = "show-my-products-only";
export const INCLUDE_SAMPLES_FILTER_ID = "include-samples";
export const QUERY_STRING_FILTER_ID = "query-string";
export const BLENDS_FILTER_ID = "blends";
export const INCLUDE_OUT_OF_STOCK_FILTER_ID = "include-out-of-stock";

export const VALID_FILTER_KEYS = new Set([
    CATEGORY_FILTER_ID,
    SUPPLIER_FILTER_ID,
    TARGET_DOI_FILTER_ID,
    SHOW_BELOW_ONLY_FILTER_ID,
    SHOW_MY_PRODUCTS_ONLY_FILTER_ID,
    INCLUDE_SAMPLES_FILTER_ID,
    QUERY_STRING_FILTER_ID,
    BLENDS_FILTER_ID,
    INCLUDE_OUT_OF_STOCK_FILTER_ID
]);

export const sortOptionsAuthenticated: SortOption[] = [
    {
        label: "Unit Price",
        type: "number",
        sortKey: "unit-price"
    },

    {
        label: "Current DOI",
        type: "number",
        sortKey: "current-doi"
    },

    {
        label: "Daily Sales",
        type: "number",
        sortKey: "daily-sales"
    }
];

export const sortOptionsPublic: SortOption[] = [
    { label: "Brand", type: "string", sortKey: "supplier" },
    { label: "Category", type: "string", sortKey: "category" }
];
