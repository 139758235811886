export default function DailySalesDisplay({
    dailySales,
    className
}: {
    dailySales: number;
    className?: string;
}) {
    return (
        <span className={`whitespace-nowrap text-gray-500 ${className}`}>
            {Number(dailySales).toFixed(2)} daily sales
        </span>
    );
}
