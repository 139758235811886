import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";

export default function RetailerInventoryDisplay({
    inventory,
    lastInventoryDate,
    className
}: {
    inventory: number;
    lastInventoryDate: string;
    className?: string;
}) {
    return (
        <div>
            <Tooltip>
                <TooltipTrigger asChild>
                    <div className={`flex w-fit flex-col ${className} gap-0.5`}>
                        <span className="text-xs text-gray-500">*Retail Inventory</span>
                        <span className="text-md whitespace-nowrap">
                            {Number(inventory).toFixed(0)} units
                        </span>
                    </div>
                </TooltipTrigger>
                <TooltipContent>
                    <p>Last Updated: {new Date(lastInventoryDate).toLocaleDateString()}</p>
                </TooltipContent>
            </Tooltip>
        </div>
    );
}
